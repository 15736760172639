export default function getAdminAuthorization(isAdmin, isSelectraAdmin, key) {
  switch (key) {
    case 'home':
    case 'overview':
    case 'form':
    case 'search':
    case 'contactPage':
    case 'logout':
      return true
    case 'users':
      return isAdmin
    case 'ipAddresses':
    case 'assistances':
    case 'monConseillerPersoProducts':
    case 'callReasons':
    case 'carbonOffset':
    case 'servicesStatus':
    case 'providerOptions':
    case 'providers':
    case 'devTools':
    case 'offices':
      return isSelectraAdmin
  }
}
