import { styled } from 'baseui'

export const StyledPanel = styled('div', ({ $theme, $tool }) => ({
  padding: '1rem',
  backgroundColor: $tool ? $theme.colors.bg050 : 'white',
  margin: '0 .5rem',
  borderRadius: '1rem',
  boxShadow: $theme.lighting.shadow400,
  overflow: 'hidden',
}))

export const StyledCardDragger = styled('div', () => ({
  zIndex: 10,
  position: 'absolute',
  top: 0,
  right: 0,
  width: '3rem',
  height: '3rem',
}))

export const StyledHeader = styled('div', () => ({
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
}))

export const StyledIcon = styled('img', () => ({
  width: '1.25rem',
  height: '1.25rem',
  marginRight: '.5rem',
}))

export const StyledTitle = styled('h3', ({ $theme }) => ({
  flexGrow: 1,
  margin: 0,
  color: $theme.colors.primary,
  ...$theme.typography.HeadingXSmall,
  fontSize: '1rem',
}))

export const StyledInfoBlock = styled('div', ({ $theme, $type, $boldText }) => {
  const outputColour = {}

  switch ($type) {
    case 'negative':
      outputColour.text = $theme.colors.negative
      outputColour.bg = $theme.colors.negative150
      outputColour.shadow = $theme.colors.negative400
      break
    case 'positive':
      outputColour.text = $theme.colors.positive
      outputColour.bg = $theme.colors.positive150
      outputColour.shadow = $theme.colors.positive400
      break
    case 'warning':
    case 'additionalSale':
      outputColour.text = $theme.colors.warning
      outputColour.bg = $theme.colors.warning150
      outputColour.shadow = $theme.colors.warning300
      break
    case 'script':
    default:
      outputColour.text = $theme.colors.primary
      outputColour.bg = $theme.colors.bg050
      outputColour.shadow = $theme.colors.primary400
      break
  }

  return {
    zIndex: '0',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    marginTop: '10px',
    marginRight: '-1rem',
    marginBottom: '1rem',
    marginLeft: '-1rem',
    color: outputColour.text,
    ...$theme.typography.ParagraphXSmall,
    fontWeight: $boldText ? 'bold' : 'normal',
    fontSize: $boldText ? '1rem' : '0.875rem',

    ':before': {
      zIndex: '-1',
      position: 'absolute',
      top: '0',
      left: '-1rem',
      width: 'calc( 100% + 2rem)',
      height: '100%',
      content: '""',
      backgroundColor: outputColour.bg,
      boxShadow: `inset 0px 0px 5px 0px ${outputColour.shadow}`,
    },
  }
})

export const InfoBlockIconOverrides = {
  Svg: {
    style: () => ({
      flex: '0 0 2rem',
      marginRight: '.5rem',
    }),
  },
}

export const draggerOverrides = {
  Svg: {
    style: () => ({
      display: 'block',
      width: '1.25rem',
      height: '1.25rem',
      marginLeft: 'auto',
    }),
  },
}
