import { useState, useEffect, useRef } from 'react'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import { ProviderOverrides } from './styled-components'
import { ChevronDown, ChevronUp } from 'baseui/icon'
import { useSelector } from 'react-redux'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { selectAllAdditionalSalesData } from '../SubscriptionFormSelectors'
import { selectAdditionalSaleModuleConfigurationData } from '../SubscriptionFormConfigurationSelectors'
import useFormStatus from '@/containers/SubscriptionFormPage/useFormStatus'
import { mapAdditionalKeyNames } from './config/salesList'
import retrieveAdditionalSalesIcons from '../../../shared/icons/retrieveAdditionalSalesIcons'

const useAdditionalSalesContainer = () => {
  const { t } = useTranslation()
  const [, theme] = useStyletron()
  const [activeTab, setActiveTab] = useState('assistanceOptions')
  const containerRef = useRef()
  const additionalSales = useSelector(selectAllAdditionalSalesData)
  const { tabToShow, shouldScroll } = useSelector(selectAdditionalSaleModuleConfigurationData)
  const { country, additionalSalesStatuses } = useSelector(selectPageConfigurationData)
  const selectedSales = Object.keys(additionalSales).filter(key => additionalSales[key]?.zohoId)
  const isAvailableAdditionalSale = key => {
    const statusKeyName = mapAdditionalKeyNames(key)
    return additionalSalesStatuses[statusKeyName] && additionalSalesStatuses[statusKeyName] === true
  }
  const hasZohoIdAdditionalSale = key => {
    return additionalSales[key]?.zohoId
  }

  const vaPrioritiesKeys = [
    'assistanceOptions',
    'carbonOffset',
    'protected',
    'axaAssurance',
    'voltalis',
    'leadMonConseillerPerso',
    'accessoriesSfr',
    'leadAxaSmartphone',
    'leadFloraAssuranceEthias',
    'leadFloraAssuranceLead',
    'leadYagoAssuranceHouse',
    'leadYagoAssuranceCar',
    'leadYagoAssuranceMoto',
    'leadSantevetAssurance',
    'leadVerisureTelecomBe',
    'leadBoilerEneco',
    'leadBoilerEngie',
    'leadBoilerHomeserve',
  ]
  const leadPrioritiesKeys = [
    'leadTelecom',
    'leadAssurance',
    'leadVerisure',
    'leadAssuranceEmprunteur',
    'telecomEnergy',
    'leadComparateur',
    'leadBankinAssurance',
    'leadElite',
    'leadEngieBrussels',
  ]

  const othersKeys = Object.keys(additionalSales).filter(
    key => !vaPrioritiesKeys.includes(key) && !leadPrioritiesKeys.includes(key),
  )
  const [showLeadPriorities, setShowLeadPriorities] = useState(true)
  const [showVaPriorities, setShowVaPriorities] = useState(true)
  const [showOthers, setShowOthers] = useState(false)
  const renderTabTitle = (text, isVisible) => {
    const arrowIcon = isVisible ? <ChevronDown size={24} /> : <ChevronUp size={24} />

    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {text}
        {arrowIcon}
      </div>
    )
  }

  const { formIsLoaded, zohoIsInValid } = useFormStatus()
  const hideComponent = () => {
    return !formIsLoaded || !country || zohoIsInValid
  }
  const hasValue = object => {
    return object && Object.values(object).some(value => value !== null && value !== '' && value !== false)
  }

  const getLogoColor = key => {
    if (additionalSales[key]?.zohoId) {
      return theme.colors.positive
    }
    if (hasValue(additionalSales[key])) {
      return theme.colors.warning
    }

    return activeTab === key ? theme.colors.primary : theme.colors.mono800
  }

  const getSaleIcon = key => {
    return retrieveAdditionalSalesIcons(key, 20, ProviderOverrides(), getLogoColor(key))
  }

  const getSaleTab = (key, Icon) => (
    <>
      {Icon}
      <div dangerouslySetInnerHTML={{ __html: '&nbsp;' + t('additionalSales:titles.' + key) }} />
    </>
  )

  const getSaleConfig = key => {
    switch (key) {
      case 'assistanceOptions':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadAssistanceOptions')),
          filePath: 'AssistanceOptionsSale',
        }
      case 'leadTelecom':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadTelecom')),
          filePath: 'LeadTelecomSale',
        }
      case 'myChauffage':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadMyChauffage')),
          filePath: 'MyChauffageSale',
        }
      case 'carbonOffset':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadCarbonOffset')),
          filePath: 'CarbonOffsetSale',
        }
      case 'garanka':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadGaranka')),
          filePath: 'GarankaSale',
        }
      case 'leadProxiserve':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadProxiserve')),
          filePath: 'LeadProxiserve',
        }
      case 'surveillanceCamera':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadSurveillanceCamera')),
          filePath: 'SurveillanceCameraSale',
        }
      case 'assurance':
      case 'leadAssurance':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadAssurance')),
          filePath: 'AssuranceSale',
        }
      case 'voltalis':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadVoltalis')),
          filePath: 'VoltalisSale',
        }
      case 'leadVoltalisBizdev':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadVoltalis')),
          filePath: 'LeadVoltalisBizdevSale',
        }
      case 'nextories':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadNextories')),
          filePath: 'NextoriesSale',
        }
      case 'leadYouStock':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadYouStock')),
          filePath: 'LeadYouStockSale',
        }
      case 'engieHomeService':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadEngieHomeService')),
          filePath: 'EngieHomeServiceSale',
        }
      case 'muterLoger':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadMuterLoger')),
          filePath: 'MuterLogerSale',
        }
      case 'leadTransferMuterLoger':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadTransferMuterLoger')),
          filePath: 'TransferMuterLogerSale',
        }
      case 'leadBank':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadBank')),
          filePath: 'LeadBankSale',
        }
      case 'leadEnergyPro':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadEnergyPro')),
          filePath: 'LeadEnergyProSale',
        }
      case 'leadGasBottle':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadGasBottle')),
          filePath: 'LeadGasBottleSale',
        }
      case 'leadFloraAssuranceEthias':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadFloraAssurance')),
          filePath: 'LeadFloraAssuranceEthiasSale',
        }
      case 'leadFloraAssuranceLead':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadFloraAssurance')),
          filePath: 'LeadFloraAssuranceLeadSale',
        }
      case 'leadSantevetAssurance':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadSantevetAssurance')),
          filePath: 'LeadSantevetAssuranceSale',
        }
      case 'leadElite':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadElite')),
          filePath: 'LeadEliteSale',
        }
      case 'leadEngieBrussels':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadEngieBrussels')),
          filePath: 'LeadEngieBrusselsSale',
        }
      case 'leadVerisureTelecomBe':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadVerisureTelecomBe')),
          filePath: 'LeadVerisureTelecomBeSale',
        }
      case 'leadBoilerEneco':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadBoilerEneco')),
          filePath: 'LeadBoilerEnecoSale',
        }
      case 'leadBoilerEngie':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadBoilerEngie')),
          filePath: 'LeadBoilerEngieSale',
        }
      case 'leadBoilerHomeserve':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadBoilerHomeserve')),
          filePath: 'LeadBoilerHomeserveSale',
        }
      case 'leadYagoAssuranceHouse':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadYagoAssurance')),
          filePath: 'LeadYagoAssuranceHouseSale',
        }
      case 'leadYagoAssuranceCar':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadYagoAssurance')),
          filePath: 'LeadYagoAssuranceCarSale',
        }
      case 'leadYagoAssuranceMoto':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadYagoAssurance')),
          filePath: 'LeadYagoAssuranceMotoSale',
        }
      case 'leadComparateur':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadComparateur')),
          filePath: 'LeadComparateurSale',
        }
      case 'leadBankinAssurance':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadBankinAssurance')),
          filePath: 'LeadBankinAssuranceSale',
        }
      case 'leadPartnership':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadPartnership')),
          filePath: 'LeadPartnershipSale',
        }
      case 'leadWater':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadWater')),
          filePath: 'WaterInteraction',
          vaType: 'interactions',
        }
      case 'edfInteraction':
        return {
          title: getSaleTab(key, getSaleIcon('sales-edfInteraction')),
          filePath: 'EDFInteraction',
          vaType: 'interactions',
        }
      case 'mesAllocs':
        return {
          title: getSaleTab(key, getSaleIcon('sales-mesAllocs')),
          filePath: 'LeadMesAllocsSale',
        }
      case 'leadGroupPurchase':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadGroupPurchase')),
          filePath: 'LeadGroupPurchase',
        }
      case 'leadAssistancePro':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadAssistancePro')),
          filePath: 'LeadAssistanceProSale',
        }
      case 'leadEnergyRenovation':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadEnergyRenovation')),
          filePath: 'LeadEnergyRenovation',
        }
      case 'proxiserveRadiator':
        return {
          title: getSaleTab(key, getSaleIcon('sales-proxiserveRadiator')),
          filePath: 'ProxiserveSaleRadiator',
        }
      case 'proxiserveWaterHeater':
        return {
          title: getSaleTab(key, getSaleIcon('sales-proxiserveWaterHeater')),
          filePath: 'ProxiserveSaleWaterHeater',
        }
      case 'leadMonConseillerPerso':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadMonConseillerPerso')),
          filePath: 'LeadMonConseillerPersoSale',
        }
      case 'leadAssuranceEmprunteur':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadAssuranceEmprunteur')),
          filePath: 'LeadAssuranceEmprunteur',
          isBlockedIfMcpCreated: true,
        }
      case 'leadBankSG':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadBankSG')),
          filePath: 'LeadBankSG',
        }
      case 'leadIsolation':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadIsolation')),
          filePath: 'LeadIsolation',
        }
      case 'leadEkwateurSolarKit':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadEkwateurSolarKit')),
          filePath: 'LeadEkwateurSolarKit',
        }
      case 'leadSolarPanelsOtovoTE':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadSolarPanelsOtovoTE')),
          filePath: 'LeadSolarPanelsOtovoTE',
        }
      case 'leadProxiservePAC':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadProxiservePAC')),
          filePath: 'LeadProxiservePAC',
        }
      case 'leadChargingPoint':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadChargingPoint')),
          filePath: 'LeadChargingPoint',
        }
      case 'leadVerisure':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadVerisure')),
          filePath: 'LeadVerisure',
          isBlockedIfMcpCreated: true,
        }
      case 'leadTucoenergie':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadTucoenergie')),
          filePath: 'LeadTucoenergie',
        }
      case 'leadOffshoreEnergie':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadOffshoreEnergie')),
          filePath: 'LeadOffshoreEnergie',
        }
      case 'leadOffshoreTelecom':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadOffshoreTelecom')),
          filePath: 'LeadOffshoreTelecom',
        }
      case 'leadAssurancePro':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadAssurancePro')),
          filePath: 'LeadAssurancePro',
        }
      case 'verisureTelecom':
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadVerisureTelecom')),
          filePath: 'VerisureTelecomSale',
        }
      case 'protected': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadProtected')),
          filePath: 'ProtectedSale',
        }
      }
      case 'axaAssurance': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-axaAssurance')),
          filePath: 'AxaAssuranceSale',
        }
      }
      case 'telecomComparator': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-telecomComparator')),
          filePath: 'TelecomComparatorSale',
        }
      }
      case 'assuranceProTelco': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-assuranceProTelco')),
          filePath: 'AssuranceProTelcoSale',
        }
      }
      case 'telecomEnergy': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-telecomEnergy')),
          filePath: 'TelecomEnergySale',
        }
      }
      case 'telecomFree': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-telecomFree')),
          filePath: 'TelecomFreeSale',
        }
      }
      case 'telecomSat': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-telecomSat')),
          filePath: 'TelecomSatSale',
        }
      }
      case 'accessoriesSfr': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-accessoriesSfr')),
          filePath: 'AccessoriesSfrSale',
        }
      }
      case 'leadCresus': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadCresus')),
          filePath: 'LeadCresusSale',
        }
      }
      case 'leadActivationMySelectra': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadActivationMySelectra')),
          filePath: 'LeadActivationMySelectraSale',
        }
      }
      case 'leadEnergyProPlaceDesEnergies': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadEnergyProPlaceDesEnergies')),
          filePath: 'LeadEnergyProPlaceDesEnergies',
        }
      }
      case 'leadAxaSmartphone': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadAxaSmartphone')),
          filePath: 'LeadAxaSmartphoneSale',
        }
      }
      case 'leadHomyos': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadHomyos')),
          filePath: 'LeadHomyosSale',
        }
      }
      case 'leadAssuranceEmprunteurMeilleurTaux': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadAssuranceEmprunteurMeilleurTaux')),
          filePath: 'LeadAssuranceEmprunteurMeilleurTauxSale',
        }
      }
      case 'leadEddyLocataire': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadEddyLocataire')),
          filePath: 'LeadEddyLocataire',
        }
      }
      case 'leadEddyProprietaire': {
        return {
          title: getSaleTab(key, getSaleIcon('sales-leadEddyProprietaire')),
          filePath: 'LeadEddyProprietaire',
        }
      }
      default:
        console.log('Component not found', key)
        return {
          title: t('additionalSales:common.component_not_found'),
          filePath: '',
        }
    }
  }

  useEffect(() => {
    if (tabToShow) {
      setActiveTab(tabToShow)
    }
    if (shouldScroll) {
      window.scrollTo({ behavior: 'smooth', top: containerRef.current.offsetTop })
    }
  }, [tabToShow, shouldScroll, setActiveTab, containerRef])

  return {
    t,
    getSaleConfig,
    selectedSales,
    hideComponent,
    vaPrioritiesKeys,
    leadPrioritiesKeys,
    othersKeys,
    activeTab,
    setActiveTab,
    containerRef,
    isAvailableAdditionalSale,
    hasZohoIdAdditionalSale,
    showLeadPriorities,
    setShowLeadPriorities,
    showVaPriorities,
    setShowVaPriorities,
    showOthers,
    setShowOthers,
    renderTabTitle,
  }
}

export default useAdditionalSalesContainer
