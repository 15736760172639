import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'
import Icon from 'baseui/icon/icon'

const IconCustomHomyos = ({ color, overrides, ref, size, title }) => {
  const [, theme] = useStyletron()

  title = title ?? 'Homyos'
  size = size ?? 16
  color = color ?? theme.colors.black

  return (
    <Icon viewBox='0 0 375 75' ref={ref} title={title} size={size} color={color} overrides={overrides}>
      <title>{title}</title>
      <g fill={color} fillOpacity="1">
        <g transform="translate(30.730096, 61.05792)">
          <path
            d="M 0.546875 0 L 12.265625 0 L 15.554688 -20.625 L 33.988281 -20.625 L 30.765625 0 L 42.484375 0 L 50.226562 -48.789062 L 38.507812 -48.789062 L 35.496094 -29.945312 L 17.0625 -29.945312 L 20.007812 -48.789062 L 8.292969 -48.789062 Z M 0.546875 0 " />
        </g>
        <g transform="translate(79.308311, 61.05792)">
          <path
            d="M 25.421875 1.507812 C 42.621094 1.507812 55.160156 -12.265625 55.160156 -29.464844 C 55.160156 -42.210938 46.59375 -50.292969 32.410156 -50.292969 C 15.144531 -50.292969 2.605469 -36.589844 2.605469 -19.324219 C 2.605469 -6.578125 11.238281 1.507812 25.421875 1.507812 Z M 26.449219 -8.566406 C 18.910156 -8.566406 14.457031 -13.15625 14.457031 -20.625 C 14.457031 -30.835938 21.105469 -40.289062 31.246094 -40.289062 C 38.851562 -40.289062 43.375 -35.699219 43.375 -28.164062 C 43.375 -18.019531 36.660156 -8.566406 26.449219 -8.566406 Z M 26.449219 -8.566406 " />
        </g>
        <g transform="translate(134.943733, 61.05792)">
          <path
            d="M 0.546875 0 L 11.238281 0 L 16.855469 -35.152344 L 16.992188 -35.152344 L 24.734375 0 L 34.398438 0 L 53.378906 -35.152344 L 53.515625 -35.082031 L 47.898438 0 L 58.585938 0 L 66.328125 -48.789062 L 50.707031 -48.789062 L 31.589844 -13.085938 L 31.453125 -13.085938 L 23.914062 -48.789062 L 8.292969 -48.789062 Z M 0.546875 0 " />
        </g>
        <g transform="translate(199.623335, 61.05792)">
          <path
            d="M 13.910156 0 L 25.695312 0 L 28.4375 -17.199219 L 49.609375 -48.789062 L 36.933594 -48.789062 L 24.121094 -27.476562 L 23.984375 -27.476562 L 17.609375 -48.789062 L 5.207031 -48.789062 L 16.652344 -17.199219 Z M 13.910156 0 " />
        </g>
        <g transform="translate(244.433164, 61.05792)">
          <path
            d="M 25.421875 1.507812 C 42.621094 1.507812 55.160156 -12.265625 55.160156 -29.464844 C 55.160156 -42.210938 46.59375 -50.292969 32.410156 -50.292969 C 15.144531 -50.292969 2.605469 -36.589844 2.605469 -19.324219 C 2.605469 -6.578125 11.238281 1.507812 25.421875 1.507812 Z M 26.449219 -8.566406 C 18.910156 -8.566406 14.457031 -13.15625 14.457031 -20.625 C 14.457031 -30.835938 21.105469 -40.289062 31.246094 -40.289062 C 38.851562 -40.289062 43.375 -35.699219 43.375 -28.164062 C 43.375 -18.019531 36.660156 -8.566406 26.449219 -8.566406 Z M 26.449219 -8.566406 " />
        </g>
        <g transform="translate(300.068597, 61.05792)">
          <path
            d="M 20.96875 1.507812 C 33.300781 1.507812 42.6875 -4.660156 42.6875 -15.144531 C 42.6875 -21.996094 38.371094 -26.242188 28.847656 -28.710938 L 23.84375 -30.148438 C 19.253906 -31.453125 17.128906 -33.097656 17.128906 -35.769531 C 17.128906 -39.125 20.007812 -41.660156 24.460938 -41.660156 C 29.601562 -41.660156 32.480469 -38.441406 32.546875 -33.988281 L 43.167969 -36.316406 C 43.167969 -44.746094 35.90625 -50.292969 25.078125 -50.292969 C 13.910156 -50.292969 5.6875 -44.195312 5.6875 -33.988281 C 5.6875 -27.273438 9.59375 -23.296875 18.5 -21.035156 L 23.640625 -19.527344 C 28.777344 -18.089844 31.246094 -16.308594 31.246094 -13.429688 C 31.246094 -9.59375 27.476562 -7.261719 22.0625 -7.261719 C 15.554688 -7.261719 11.648438 -11.03125 11.71875 -16.789062 L 0.820312 -15.007812 C 0.820312 -5.003906 8.976562 1.507812 20.96875 1.507812 Z M 20.96875 1.507812 " />
        </g>
      </g>
    </Icon>
  )
}

IconCustomHomyos.propTypes = {
  color: PropTypes.string,
  overrides: PropTypes.object,
  ref: PropTypes.object,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default IconCustomHomyos
