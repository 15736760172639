import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

import { InfoBlockIconOverrides, StyledInfoBlock } from '../styled-components'
import IconCarbonMisuseOutline from '@/shared/icons/IconCarbonMisuseOutline'
import IconCarbonWarningAlt from '@/shared/icons/IconCarbonWarningAlt'
import IconCarbonCheckmarkOutline from '@/shared/icons/IconCarbonCheckmarkOutline'
import IconCarbonVoiceActivate from '@/shared/icons/IconCarbonVoiceActivate'
import IconCarbonInformation from '@/shared/icons/IconCarbonInformation'
import IconCarbonRocket from '@/shared/icons/IconCarbonRocket'

const PanelInfoBlock = ({ children, icon, type, boldText = false }) => {
  const [, theme] = useStyletron()
  const iconProps = {
    size: 24,
    color:
      type === 'negative' || type === 'warning' || type === 'positive'
        ? theme.colors[type]
        : type === 'additionalSale'
          ? theme.colors.warning
          : theme.colors.primary,
    overrides: InfoBlockIconOverrides,
  }
  const getIcon = () => {
    switch (type) {
      case 'negative':
        return <IconCarbonMisuseOutline {...iconProps} />
      case 'warning':
        return <IconCarbonWarningAlt {...iconProps} />
      case 'positive':
        return <IconCarbonCheckmarkOutline {...iconProps} />
      case 'script':
        return <IconCarbonVoiceActivate {...iconProps} />
      case 'additionalSale':
        return <IconCarbonRocket {...iconProps} />
      default:
        return <IconCarbonInformation {...iconProps} />
    }
  }
  const outputIcon = (() => {
    if (icon !== false) {
      if (typeof icon === 'function') {
        return icon(iconProps)
      }
      return getIcon()
    }
    return ''
  })()

  return (
    <StyledInfoBlock $type={type} $boldText={boldText}>
      {outputIcon}
      {children}
    </StyledInfoBlock>
  )
}

PanelInfoBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.func, PropTypes.object]),
  type: PropTypes.oneOf(['', 'additionalSale', 'default', 'negative', 'positive', 'script', 'warning']),
  boldText: PropTypes.bool,
}

export default PanelInfoBlock
