import IconCustomEnedis from '@/shared/icons/IconCustomEnedis'
import IconCarbonThumbsUp from '@/shared/icons/IconCarbonThumbsUp'
import IconCarbonInformation from '@/shared/icons/IconCarbonInformation'
import IconCarbonCarbonAccounting from '@/shared/icons/IconCarbonCarbonAccounting'
import IconCustomAxa from '@/shared/icons/IconCustomAxa'
import IconCarbonVideo from '@/shared/icons/IconCarbonVideo'
import IconCustomVoltalis from '@/shared/icons/IconCustomVoltalis'
import IconCarbonRule from '@/shared/icons/IconCarbonRule'
import IconCarbonSatelliteRadar from '@/shared/icons/IconCarbonSatelliteRadar'
import IconCarbonScales from '@/shared/icons/IconCarbonScales'
import IconCarbonMobile from '@/shared/icons/IconCarbonMobile'
import IconCarbonLightning from '@/shared/icons/IconCarbonLightning'
import IconCarbonChargingStation from '@/shared/icons/IconCarbonChargingStation'
import IconCarbonAwake from '@/shared/icons/IconCarbonAwake'
import IconCarbonHome from '@/shared/icons/IconCarbonHome'
import IconCarbonFinance from '@/shared/icons/IconCarbonFinance'
import IconCarbonPiggyBankSlot from '@/shared/icons/IconCarbonPiggyBankSlot'
import IconCustomGaranka from '@/shared/icons/IconCustomGaranka'
import IconCustomNextories from '@/shared/icons/IconCustomNextories'
import IconCustomEngie from '@/shared/icons/IconCustomEngie'
import IconCustomIzi from '@/shared/icons/IconCustomIzi'
import IconCarbonEventsAlt from '@/shared/icons/IconCarbonEventsAlt'
import IconCarbonPiggyBank from '@/shared/icons/IconCarbonPiggyBank'
import IconCustomMesAllocs from '@/shared/icons/IconCustomMesAllocs'
import IconCustomMuterLoger from '@/shared/icons/IconCustomMuterLoger'
import IconCarbonFire from '@/shared/icons/IconCarbonFire'
import IconCarbonTwoPersonLift from '@/shared/icons/IconCarbonTwoPersonLift'
import IconCarbonToolKit from '@/shared/icons/IconCarbonToolKit'
import IconCarbonWater from '@/shared/icons/IconCarbonWater'
import IconCarbonEDFForm from '@/shared/icons/IconCarbonEDFform'
import IconCarbonEnergyRenovation from '@/shared/icons/IconCarbonEnergyRenovation'
import IconCarbonProxiserveRadiator from '@/shared/icons/IconCarbonProxiserveRadiator'
import IconCarbonProxiserveWaterHeater from '@/shared/icons/IconCarbonProxiserveWaterHeater'
import IconCarbonMonConseillerPerso from '@/shared/icons/IconCarbonMonConseillerPerso'
import IconCarbonNotification from '@/shared/icons/IconCarbonNotification'
import IconCarbonHeadphones from '@/shared/icons/IconCarbonHeadphones'
import IconCarbonAirlinePassengerCare from '@/shared/icons/IconCarbonAirlinePassengerCare'
import IconCarbonCurrencyEuro from '@/shared/icons/IconCarbonCurrencyEuro'
import IconCarbonBadge from './IconCarbonBadge'
import IconMySelectraCheck from '@/shared/icons/IconMySelectraCheck'
import IconCustomHomyos from '@/shared/icons/IconCustomHomyos'

const retrieveAdditionalSalesIcons = (name, size = 20, overrides, color = null) => {
  switch (name) {
    case 'enedis-api':
      return <IconCustomEnedis size={size} color={color} overrides={overrides} />
    case 'ekomi-status':
      return <IconCarbonThumbsUp size={size} color={color} overrides={overrides} />
    case 'sales-leadCarbonOffset':
      return <IconCarbonCarbonAccounting color={color} size={size} overrides={overrides} />
    case 'sales-leadAssistanceOptions':
    case 'sales-axaAssurance':
    case 'sales-leadAssistancePro':
    case 'saleAssistancePro':
    case 'sales-leadAxaSmartphone':
      return <IconCustomAxa size={size} color={color} overrides={overrides} />
    case 'sales-leadSurveillanceCamera':
      return <IconCarbonVideo size={size} color={color} overrides={overrides} />
    case 'sales-leadVoltalis':
    case 'sales-leadVoltalisBizdev':
      return <IconCustomVoltalis size={size} color={color} overrides={overrides} />
    case 'sales-leadAssurance':
    case 'saleAssurance':
    case 'sales-leadBankinAssurance':
    case 'sales-leadYagoAssurance':
    case 'sales-assuranceProTelco':
    case 'sales-leadProtected':
    case 'sales-leadAssurancePro':
      return <IconCarbonRule size={size} color={color} overrides={overrides} />
    case 'sales-telecomComparator':
      return <IconCarbonScales color={color} size={size} overrides={overrides} />
    case 'sales-telecomSat':
    case 'sales-leadTelecom':
      return <IconCarbonSatelliteRadar size={size} color={color} overrides={overrides} />
    case 'sales-leadComparateur':
      return <IconCarbonThumbsUp size={size} color={color} overrides={overrides} />
    case 'sales-telecomFree':
      return <IconCarbonMobile size={size} color={color} overrides={overrides} />
    case 'sales-telecomEnergy':
    case 'sales-leadEnergyPro':
    case 'sales-leadEnergyProPlaceDesEnergies':
    case 'saleEnergyPro':
    case 'sales-leadOffshoreEnergie':
    case 'sales-leadOffshoreTelecom':
      return <IconCarbonLightning size={size} color={color} overrides={overrides} />
    case 'sales-leadChargingPoint':
      return <IconCarbonChargingStation size={size} color={color} overrides={overrides} />
    case 'sales-leadProxiservePAC':
      return <IconCarbonProxiserveWaterHeater size={size} color={color} overrides={overrides} />
    case 'sales-leadSolarPanelsOtovoTE':
    case 'sales-leadEkwateurSolarKit':
      return <IconCarbonAwake size={size} color={color} overrides={overrides} />
    case 'sales-leadIsolation':
    case 'sales-leadTucoenergie':
      return <IconCarbonHome size={size} color={color} overrides={overrides} />
    case 'sales-leadBankSG':
      return <IconCarbonPiggyBankSlot size={size} color={color} overrides={overrides} />
    case 'sales-leadBank':
      return <IconCarbonPiggyBank size={size} color={color} overrides={overrides} />
    case 'sales-leadEddyLocataire':
    case 'sales-leadEddyProprietaire':
    case 'sales-leadAssuranceEmprunteur':
    case 'sales-leadAssuranceEmprunteurMeilleurTaux':
      return <IconCarbonFinance size={size} color={color} overrides={overrides} />
    case 'sales-leadMonConseillerPerso':
    case 'saleSelectraService':
      return <IconCarbonMonConseillerPerso color={color} size={size} overrides={overrides} />
    case 'sales-proxiserveWaterHeater':
      return <IconCarbonProxiserveWaterHeater size={size} color={color} overrides={overrides} />
    case 'sales-proxiserveRadiator':
      return <IconCarbonProxiserveRadiator size={size} color={color} overrides={overrides} />
    case 'sales-leadEnergyRenovation':
      return <IconCarbonEnergyRenovation size={size} color={color} overrides={overrides} />
    case 'sales-edfInteraction':
      return <IconCarbonEDFForm size={size} color={color} overrides={overrides} />
    case 'sales-leadGroupPurchase':
      return <IconCarbonEventsAlt color={color} size={size} overrides={overrides} />
    case 'sales-mesAllocs':
    case 'saleAdministrativeService':
      return <IconCustomMesAllocs size={size} color={color} overrides={overrides} />
    case 'sales-leadWater':
      return <IconCarbonWater size={size} color={color} overrides={overrides} />
    case 'sales-leadProxiserve':
    case 'sales-leadBoilerEneco':
    case 'sales-leadBoilerEngie':
    case 'sales-leadBoilerHomeserve':
      return <IconCarbonToolKit size={size} color={color} overrides={overrides} />
    case 'sales-leadPartnership':
      return <IconCarbonTwoPersonLift size={size} color={color} overrides={overrides} />
    case 'sales-leadGasBottle':
      return <IconCarbonFire color={color} size={size} overrides={overrides} />
    case 'sales-leadTransferMuterLoger':
    case 'sales-leadMuterLoger':
      return <IconCustomMuterLoger size={size} color={color} overrides={overrides} />
    case 'sales-leadEngieHomeService':
    case 'sales-leadEngieBrussels':
      return <IconCustomEngie size={size} color={color} overrides={overrides} />
    case 'sales-leadNextories':
      return <IconCustomNextories color={color} size={size} overrides={overrides} />
    case 'sales-leadGaranka':
      return <IconCustomGaranka size={size} color={color} overrides={overrides} />
    case 'sales-leadMyChauffage':
      return <IconCustomIzi size={size} color={color} overrides={overrides} />
    case 'sales-leadVerisureTelecom':
    case 'sales-leadVerisureTelecomBe':
    case 'sales-leadVerisure':
      return <IconCarbonNotification size={size} color={color} overrides={overrides} />
    case 'sales-accessoriesSfr':
      return <IconCarbonHeadphones size={size} color={color} overrides={overrides} />
    case 'sales-leadFloraAssurance':
      return <IconCarbonHome size={size} color={color} overrides={overrides} />
    case 'sales-leadSantevetAssurance':
      return <IconCarbonAirlinePassengerCare size={size} color={color} overrides={overrides} />
    case 'sales-leadElite':
      return <IconCarbonBadge size={size} color={color} overrides={overrides} />
    case 'sales-leadCresus':
    case 'saleCresus':
      return <IconCarbonCurrencyEuro size={size} color={color} overrides={overrides} />
    case 'sales-leadActivationMySelectra':
      return <IconMySelectraCheck size={size} color={color} overrides={overrides} />
    case 'sales-leadHomyos':
      return <IconCustomHomyos size={30} color={color} overrides={overrides} />
    default:
      console.log('No icon setted for', name)
      return <IconCarbonInformation size={size} color={color} overrides={overrides} />
  }
}

export default retrieveAdditionalSalesIcons
