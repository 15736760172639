import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const useBusinessType = ({
  loaderRoutesEnergy,
  loaderRoutesInsurance = {},
  loaderRoutes,
  modulesStatuses,
  modulesStatusesEnergy,
  modulesStatusesInsurance = {},
}) => {
  const { type } = useParams()
  const getApplicationTypeFolder = () => {
    if (type === 'energie') {
      return 'Energy'
    }
    if (type === 'assurance') {
      return 'Insurance'
    }
    return 'Telecom'
  }
  const getApplicationRoutes = () => {
    if (type === 'energie') {
      return loaderRoutesEnergy ?? {}
    }
    if (type === 'assurance') {
      return loaderRoutesInsurance ?? {}
    }
    return loaderRoutes ?? {}
  }
  const getApplicationUrl = page => {
    const applicationPaths = getApplicationRoutes()
    return applicationPaths[page] ?? ''
  }
  const getModulesStatuses = applicationType => {
    if (applicationType === 'energie') {
      return modulesStatusesEnergy
    }
    if (applicationType === 'assurance') {
      return modulesStatusesInsurance
    }
    return modulesStatuses
  }

  const getApplicationMenuRoutes = applicationType => {
    if (applicationType === 'energie') {
      return {
        home: '/energie',
        overview: '/energie/subscription/list/overview',
        form: '/energie/subscription/add',
        users: '/users-mngt',
        ipAddresses: '/ip-addresses-management',
        offices: '/energie/offices',
        providers: '/energie/organisations/provider',
        servicesStatus: '/energie/services-status',
        devTools: '/energie/dev-tools/tools',
        assistances: '/energie/additional-sales/assistances',
        monConseillerPersoProducts: '/energie/additional-sales/mon-conseiller-perso',
        carbonOffset: '/energie/additional-sales/carbon-offset',
        contactPage: '/contact-page',
        logout: '/logout',
      }
    }
    if (applicationType === 'assurance') {
      return {
        home: '/assurance',
        overview: '/assurance/subscription/list/overview',
        form: '/assurance/subscription/add',
        users: '/users-mngt',
        ipAddresses: '/ip-addresses-management',
        providers: '/assurance/organisations/provider',
        servicesStatus: '/assurance/services-status',
        devTools: '/assurance/dev-tools/tools',
        assistances: '/assurance/additional-sales/assistances',
        monConseillerPersoProducts: '/assurance/additional-sales/mon-conseiller-perso',
        carbonOffset: '/assurance/additional-sales/carbon-offset',
        contactPage: '/contact-page',
        logout: '/logout',
      }
    }
    return {
      home: '/telecom',
      overview: '/telecom/subscription/list/overview',
      form: '/telecom/subscription/add',
      users: '/users-mngt',
      ipAddresses: '/ip-addresses-management',
      providers: '/telecom/organisations/provider',
      providerOptions: '/telecom/provider-options',
      servicesStatus: '/telecom/services-status',
      devTools: '/telecom/dev-tools/tools',
      assistances: '/telecom/additional-sales/assistances',
      monConseillerPersoProducts: '/telecom/additional-sales/mon-conseiller-perso',
      carbonOffset: '/telecom/additional-sales/carbon-offset',
      contactPage: '/contact-page',
      logout: '/logout',
    }
  }

  const translateBusinessTypeToUrl = businessType => {
    if (businessType === 'energy') {
      return 'energie'
    }
    if (businessType === 'insurance') {
      return 'assurance'
    }
    return 'telecom'
  }

  useEffect(() => {
    if (!type) {
      document.title = 'HOP'
      return
    }

    document.title = 'HOP ' + type.toUpperCase()
  }, [])

  return {
    type,
    typeTranslated: getApplicationTypeFolder(type),
    getApplicationUrl,
    getApplicationMenuRoutes,
    getModulesStatuses,
    getApplicationTypeFolder,
    translateBusinessTypeToUrl,
  }
}

export default useBusinessType
