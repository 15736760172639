import WelcomeMessage from './WelcomeMessage/WelcomeMessage'
import SalesAgentBonusStats from './SalesAgentsBonusStats/SalesAgentsBonusStats'
import TopSalesAgents from './SalesAgentsBonusStats/TopSalesAgents'
import { useTranslation } from 'react-i18next'
import useHome from './useHome'
import useHomeStyles from './useHomeStyles'
import SpeedFormation from './SpeedFormation/SpeedFormation'
import useBusinessType from '@/shared/hooks/useBusinessType'
import VonageSwitchModule from './VonageSwitchModule/VonageSwitchModule'

const Home = () => {
  const { data, setData, vonageDialerStatus, isExternal } = useHome()
  const { cardStyle, cardBonuses, headingStyle, parent, child } = useHomeStyles()
  const { t } = useTranslation()
  const { type } = useBusinessType({ loaderRoutesEnergy: {}, loaderRoutes: {} })

  if (!data) {
    return (
      <div className={cardStyle}>
        <p>Fetching data...</p>
      </div>
    )
  }

  return (
    <>
      <div className={parent} id='home-welcome'>
        <div className={cardStyle + child}>
          <h1 className={headingStyle}>{t('home:home_welcome')}</h1>
          <WelcomeMessage data={data} setData={setData} />
        </div>
        {!isExternal && (
          <div className={cardStyle + child}>
            <h1 className={headingStyle}>{t('home:speed_formation.title')}</h1>
            <SpeedFormation data={data} setData={setData} />
          </div>
        )}
      </div>

      {!data.isAdmin && (
        <div className={cardBonuses}>
          <h1 className={headingStyle}>{t('home:bonus_summary')}</h1>
          <SalesAgentBonusStats bonuses={data.bonuses ?? {}} />
        </div>
      )}

      {vonageDialerStatus && !isExternal && (
        <VonageSwitchModule vonageStatusDb={data.vonageStatus} vonageUpdateUrl={data.vonageUpdateUrl} />
      )}
      {type === 'energie' && (
        <div className={cardStyle}>
          <h1 className={headingStyle}>{t('home:bonus_top_agents_primes')}</h1>
          <TopSalesAgents salesAgentsBySite={data.topSalesAgentsBySite} />
        </div>
      )}
    </>
  )
}

export default Home
