import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SIZE, KIND } from 'baseui/button'
import useBusinessType from '@/shared/hooks/useBusinessType'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { StyledSubmission } from '../form-header-styled-components'
import useHttp from '@/shared/hooks/useHttp'
import ModalWindowSubmission from './ModalWindowSubmission'
import {
  selectBillingSubscriptionData,
  selectCustomerSubscriptionData,
  selectElectricityMeterSubscriptionData,
  selectEnergyCharacteristicsSubscriptionData,
  selectEnergyConsumptionData,
  selectEnergyContractSubscriptionData,
  selectEnergyEstimationData,
  selectGasMeterSubscriptionData,
  selectInsuranceCharacteristicsSubscriptionData,
  selectInsuranceContractSubscriptionData,
  selectInvoiceAddressSubscriptionData,
  selectMainAddressSubscriptionData,
  selectPropaneSubscriptionData,
  selectTelecomCharacteristicsSubscriptionData,
  selectTelecomContractSubscriptionData,
} from '@/containers/SubscriptionFormPage/SubscriptionFormSelectors'
import { useSelector } from 'react-redux'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import {
  selectCommonFormConfigurationData,
  selectOfferModuleConfigurationData,
} from '@/containers/SubscriptionFormPage/SubscriptionFormConfigurationSelectors'
import useFormStatus from '../../../../containers/SubscriptionFormPage/useFormStatus'
import useSaveForm from '../../../../containers/SubscriptionFormPage/SubscriptionForm/useSaveForm'

const FormSubmission = () => {
  const { t } = useTranslation()
  const { duplicate_subscription_url: duplicateSubscriptionUrl, country } = useSelector(selectPageConfigurationData)
  const customerData = useSelector(selectCustomerSubscriptionData)
  const billingData = useSelector(selectBillingSubscriptionData)
  const electricityMeterData = useSelector(selectElectricityMeterSubscriptionData)
  const gasMeterData = useSelector(selectGasMeterSubscriptionData)
  const mainAddressData = useSelector(selectMainAddressSubscriptionData)
  const telecomContractData = useSelector(selectTelecomContractSubscriptionData)
  const energyContractData = useSelector(selectEnergyContractSubscriptionData)
  const telecomCharacteristicsData = useSelector(selectTelecomCharacteristicsSubscriptionData)
  const energyCharacteristicsData = useSelector(selectEnergyCharacteristicsSubscriptionData)
  const invoicingAddressData = useSelector(selectInvoiceAddressSubscriptionData)
  const energyEstimationData = useSelector(selectEnergyEstimationData)
  const energyConsumptionData = useSelector(selectEnergyConsumptionData)
  const propaneData = useSelector(selectPropaneSubscriptionData)
  const insuranceContractData = useSelector(selectInsuranceContractSubscriptionData)
  const insuranceCharacteristicsData = useSelector(selectInsuranceCharacteristicsSubscriptionData)
  const { id, saveUrl } = useSelector(selectCommonFormConfigurationData)
  const { offerIsAvailable } = useSelector(selectOfferModuleConfigurationData)
  const { type: applicationType } = useBusinessType({
    loaderRoutesEnergy: {},
    loaderRoutesInsurance: {},
    loaderRoutes: {},
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const [formDuplicated, setFormDuplicated] = useState(false)
  const { formIsLoaded, isEditable, canSendSubscription, formCanBeSubmitted, formSubmitted } = useFormStatus()
  const [isOpen, setIsOpen] = useState(false)
  const { sendRequest, isLoading, getConfig } = useHttp()
  const { submitSubscriptionForm } = useSaveForm()
  const submitForm = async event => {
    event.preventDefault()
    submitSubscriptionForm({
      actionName: 'save_and_send',
      headerConfig: getConfig('POST'),
      applicationType,
      customerData,
      billingData,
      electricityMeterData,
      gasMeterData,
      mainAddressData,
      invoicingAddressData,
      telecomContractData,
      energyContractData,
      telecomCharacteristicsData,
      energyCharacteristicsData,
      energyConsumptionData,
      energyEstimationData,
      propaneData,
      insuranceContractData,
      insuranceCharacteristicsData,
      id,
      country,
      saveUrl,
    })
  }

  const openModal = async event => {
    event.preventDefault()
    setIsOpen(true)
  }

  const duplicate = async event => {
    event.preventDefault()
    setFormDuplicated(true)
    if (isEditable && canSendSubscription) {
      await saveData(event, 'save_without_status')
    }
    const headers = getConfig('POST')
    const options = new URLSearchParams()
    options.append('id', id)
    options.append('lang', country)
    headers.body = options

    sendRequest(duplicateSubscriptionUrl, headers, json => {
      setFormDuplicated(false)
      if (json.id) {
        window.open(window.location.origin + '/' + applicationType + '/subscription/edit/' + json.id, '_blank')
      }
    })
  }

  const saveData = async (event, saveStatus) => {
    event.preventDefault()
    await submitSubscriptionForm({
      actionName: saveStatus,
      headerConfig: getConfig('POST'),
      applicationType,
      customerData,
      billingData,
      electricityMeterData,
      gasMeterData,
      mainAddressData,
      invoicingAddressData,
      telecomContractData,
      energyContractData,
      telecomCharacteristicsData,
      energyCharacteristicsData,
      energyConsumptionData,
      energyEstimationData,
      propaneData,
      insuranceContractData,
      insuranceCharacteristicsData,
      id,
      country,
      saveUrl,
    })
  }

  return (
    <StyledSubmission>
      {formIsLoaded && (
        <SDButton
          size={SIZE.compact}
          kind={KIND.secondary}
          overrides={{
            BaseButton: {
              style: () => ({
                marginRight: '1rem',
              }),
            },
          }}
          onClick={event => duplicate(event)}
          isLoading={formDuplicated}
        >
          {t('subscriptionForm:header.duplicate')}
        </SDButton>
      )}
      {formIsLoaded && isEditable === true && offerIsAvailable === true && (
        <SDButton
          size={SIZE.compact}
          kind={KIND.secondary}
          overrides={{
            BaseButton: {
              style: () => ({
                marginRight: '1rem',
              }),
            },
          }}
          onClick={event => saveData(event, 'save')}
          isLoading={formSubmitted}
          disabled={formSubmitted}
        >
          {t('subscriptionForm:header.save_in_not_sold_to_callback')}
        </SDButton>
      )}

      {formIsLoaded && isEditable === true && offerIsAvailable === true && (
        <SDButton
          id='button-save-and-send'
          size={SIZE.default}
          onClick={event => openModal(event)}
          isLoading={formSubmitted}
          disabled={formSubmitted || formCanBeSubmitted === false || canSendSubscription === false}
        >
          {applicationType === 'telecom'
            ? t('subscriptionForm:header.save_submit_zoho_in_progress')
            : t('subscriptionForm:header.save_submit_zoho')}
        </SDButton>
      )}
      {
        <ModalWindowSubmission
          submitForm={submitForm}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          sendRequest={sendRequest}
          isLoading={isLoading}
          country={country}
        />
      }
    </StyledSubmission>
  )
}

export default FormSubmission
